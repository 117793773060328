<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl12>

        <!-- Header Section -->
        <v-layout wrap justify-start class="my-3">
          <v-flex xs12 sm9 md9 lg10 text-start align-center pt-2 class="headingChurch">Bible Quotes List</v-flex>
          <v-flex xs12 sm3 md3 lg2 text-end align-center><v-btn class="churchbtn" color="blue" style="color:white"
              @click="dialog = true">ADD QUOTES</v-btn></v-flex>

          <v-dialog v-model="dialog" persistent max-width="700px">
            <AddBibleQuotes @stepper="winStepper" />
          </v-dialog>

        </v-layout>


        <v-layout wrap justify-start>
          <v-flex xs12>
            <!-- filter Section -->
            <v-layout wrap justify-start pb-3 class="text-des">

              <v-flex xs12 sm6 md4 lg4 pr-lg-2 pr-sm-2 pr-md-2 pr-0  pt-2 pt-lg-0 pt-sm-0 pt-md-0>
                <v-text-field :hide-details="true" v-model="date" label="Select Date" readonly outlined flat dense solo clearable
                        @click="showDatePicker = !showDatePicker"
                        :append-icon="showDatePicker ? 'mdi-calendar-remove' : 'mdi-calendar'"></v-text-field>
                        <v-dialog v-model="showDatePicker" persistent  max-width="290px" :style="{ 'z-index': 2000 }"
                        @click:outside="showDatePicker = false">
                        <v-date-picker v-model="date" @input="updatedDate"></v-date-picker>
                      </v-dialog>
                    </v-flex>


          
                  <v-flex xs12 sm6 md4 lg4 pr-lg-2 pr-sm-2 pt-2 pt-lg-0 pt-sm-0 pt-md-0 pr-md-2 pr-0 >
                  <v-text-field :hide-details="true"  v-model="fromDate" class="text-des" label="From Date" readonly solo outlined dense flat clearable
                  @click="showFromDatePicker = !showFromDatePicker"
                  :append-icon="showFromDatePicker ? 'mdi-calendar-remove' : 'mdi-calendar'"></v-text-field>
                   <v-dialog v-model="showFromDatePicker" persistent  max-width="290px" :style="{ 'z-index': 2000 }"
                  @click:outside="showFromDatePicker = false">
                  <v-date-picker  v-model="fromDate" @input="updateFromDate"></v-date-picker>
                </v-dialog>
              </v-flex>
                
              <v-flex xs12 sm6 md4 lg4    pr-lg-0 pr-sm-2 pr-md-0 pr-0 pt-sm-3 pt-lg-0 pt-md-0 pt-2>
                  <v-text-field :hide-details="true" v-model="toDate" label="To Date" readonly outlined flat solo dense clearable
                  @click="showToDatePicker = !showToDatePicker"
                  :append-icon="showToDatePicker ? 'mdi-calendar-remove' : 'mdi-calendar'"></v-text-field>
                  <v-dialog v-model="showToDatePicker" persistent  max-width="290px"  :style="{ 'z-index': 2000 }"
                  @click:outside="showToDatePicker = false">
                  <v-date-picker v-model="toDate" @input="updateToDate"></v-date-picker>
                </v-dialog>
                </v-flex>

               

              
              </v-layout>

            <!-- table section -->

            <v-layout wrap justify-center pb-3>
              <v-flex xs12>
                <v-data-table :headers="headers" :items="quoteslist" hide-default-footer class="elevation-1 text--black"
                  id="virtual-scroll-table">
                  <template v-slot:[`item.showDate`]="{ item }">
                    <span>{{ formatDate1(item.showDate) }}</span>
                  </template>
                  <template v-slot:[`item._id`]="{ item }">
                    <v-icon small color="primary" class="ml-1" @click.stop="editSlider(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small color="red" class="ml-1" @click.stop="openDeleteDialog(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-2>
              <v-flex xs12>
                <div class="text-center pb-5" v-if="pages > 1">
                  <v-pagination :length="pages" v-model="currentPage" color="primary white--text"
                    circle></v-pagination>
                </div>
              </v-flex>
            </v-layout>

          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>




    <!-- delete dialog -->
    <v-dialog v-model="deletedialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class="grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Deletion</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            Are you sure you want to delete this Bible Quote?
            <br />
            This action <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
          <v-row class="pt-2" no-gutters>
            <v-col>
              <img src="@/assets/images/bible.png" width="70px" height="70px" class="dialog-icon" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2">
          <v-btn style="font-family: interBold; font-size: 13px; color: white;" color="#cf3a45"
            @click="deletedialog = false">Cancel</v-btn>
          <v-btn style="font-family: interBold; font-size: 13px; color: white;" color="blue darken-1"
            @click="confirmDelete">OK</v-btn>
        </v-card-actions>
      </v-card>




    </v-dialog>


    <!--edit dialog  -->
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="editbox">EDIT BIBLE QUOTES</span>
          <v-spacer></v-spacer>

        </v-card-title>

        <v-card-text>
          <v-form>

            <v-layout wrap justify-center px-2>
              <v-flex xs12 pr-2>
                  <span class="label ">
                    Bible Quote
                  </span>
                   <v-textarea class="pt-2 text-des" style="font-size:14px" v-model="editingitem.verse"
                      outlined dense hide-details></v-textarea>
              </v-flex>

              <v-flex xs12 pr-2 pt-3>
                <span class="label ">
                  Author
                </span>
                 <v-text-field class="pt-2 text-des" style="font-size:14px" v-model="editingitem.author"
                    outlined dense hide-details></v-text-field>
            </v-flex>

            <v-flex xs12 pr-2 pt-3>
              <span class="label ">
                Chapter
              </span>
               <v-text-field class="pt-2 text-des" style="font-size:14px" v-model="editingitem.chapter"
                  outlined dense hide-details></v-text-field>
          </v-flex>

          <v-flex xs12 pr-2 pt-3>
            <span class="label ">
              Added Date
            </span>
           
                <v-menu ref="menu" class="pt-2 text-des" style="font-size:14px" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="formattedDate" outlined dense hide-details v-bind="attrs" v-on="on"
                    readonly></v-text-field>
                </template>
                <v-date-picker   :min="nowDate" v-model="formattedDate" @input="updateDate" locale="en"></v-date-picker>
              </v-menu>
        </v-flex>




            </v-layout>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4 justify-end headline grey lighten-2">
          <v-btn outlined color="grey" text @click="closeDialog()"><span style="color: black;">Cancel</span>
          </v-btn>
          <v-btn color="primary" @click="validateInputEdit(editingitem._id)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import AddBibleQuotes from './addBibleQuotes';
import axios from 'axios';
export default {
  components: {


    AddBibleQuotes
  },
  data() {
    return {
      showSnackBar:false,
      timeout:5000,
      msg: "",
      dialog: false,
      deletedialog: false, 
      quoteslist: [],
      editdialog: false,
      editingitem: {
        showDate: '' ,// Initialize with your date string
        appLoading: false,
        fromDateMenu: false,
      toDateMenu: false,
      fromDate: '',
      toDate: '',
      },

      fromDate: null, // Initial from date in YYYY-MM-DD format
      toDate: null,   // Initial to date in YYYY-MM-DD format
      date:null,
      formattedFromDate: '',
      formattedToDate: '',
      formattDate: '',
      showFromDatePicker: false,
      showToDatePicker: false,
      showDatePicker: false,
      page: 1,
        currentPage: 1,
        pages: 0,
        limit: 10,
      menu: false, // Controls the visibility of the menu
      editDate: '', // Initialize as an empty string
      headers: [
        { text: "Bible Verse", value: "verse", width: "450px" },
        { text: "Author", value: "author", width: "30px" },
        { text: "Chapter", value: "chapter", width: "90px" },
        { text: "Date", value: "showDate", width: "110px" },
        { text: "Actions", value: "_id", width: "5px", }

      ],
    };
  },
  computed: {
  formattedDate: {
      get() {
        // Format date as 'YYYY-MM-DD'
        return this.editDate ? this.formatDate(this.editDate) : '';
      },
      set(newValue) {
        // Convert string to Date object and update
        this.editDate = newValue;
        this.editingitem.showDate = this.parseDate(newValue);
      }
    },
    nowDate() {
      const now = new Date();
      return now.toISOString().substr(0, 10);
    },
  },
  mounted() {
  this.getData(); // Fetch data when component mounts
},
  watch: {
    currentPage() {
        this.getData();
      },
    date() {
    //this.formattDate = newDate;
    this.currentPage=1
    this.getData();
    
  },
  fromDate() {
    this.currentPage=1
    //this.formattedFromDate = newDate;
    this.getData();
  },
  toDate() {
    this.currentPage=1
    //this.formattedToDate = newDate;
    
    // Fetch data whenever toDate changes
    this.getData();
   
  },
  
  editingitem: {
      deep: true,
      handler() {
        if (this.editingitem && this.editingitem.showDate) {
          this.editDate = this.formatDate(new Date(this.editingitem.showDate));
        }
      }
    }
},
  methods: {
    closeDialog() {
      this.editdialog = false;
      this.getData();
    },
    formatDate1(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB').replace(/\//g, '-');
    },
    updatedDate(date) {
      this.date = date;
      this.formattDate = date;
      this.showDatePicker = false;
      this.getData(); // Fetch data when toDate changes
      this.fromDate = "";
    this.toDate = "";
    },

    updateFromDate(date) {
      this.fromDate = date;
      this.formattedFromDate = date;
      this.showFromDatePicker = false;
      this.getData(); // Fetch data when fromDate changes
      this.toDate = "";
      this.date = "";
    },
    updateToDate(date) {
      this.toDate = date;
      this.formattedToDate = date;
      this.showToDatePicker = false;
      this.getData(); // Fetch data when toDate changes
      this.date = "";
    },
 


  updateDate(date) {
      this.menu = false; // Close the menu after selecting the date
      this.editDate = date;
      this.editingitem.showDate = this.parseDate(date);
    },
    formatDate(date) {
      if (!date) return '';
      // Ensure date is formatted to 'YYYY-MM-DD'
      return date instanceof Date ? date.toISOString().slice(0, 10) : date;
    },
    parseDate(dateString) {
      // Convert 'YYYY-MM-DD' format to a Date object
      return new Date(dateString).toISOString();
    },
    winStepper(item) {
      if (item.ref == 'addBibleQuotes') {
        this.dialog = item.dialog
        this.getData();
      }
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/get/bible/verse",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
            fromDate: this.fromDate,
            toDate:this.toDate,
            date:this.date
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.quoteslist = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;

    },

    validateInputEdit() {
 if (!this.editingitem.verse) {
    this.msg = "Please provide a bible quote";
    this.showSnackBar = true;
    return;
  } 
  else if (!this.editingitem.author) {
    this.msg = "Please provide author ";
    this.showSnackBar = true;
    return;
  } 
  else if (!this.editingitem.chapter) {
    this.msg = "Please provide chapter";
    this.showSnackBar = true;
    return;
  } 
  else if (!this.editingitem.showDate) {
    this.msg = "Please provide date";
    this.showSnackBar = true;
    return;
  } 
 
  else {
    this.edit();
  }
},


    edit() {
      this.appLoading = true;
      var user = {};
      user["id"] = this.editingitem._id;
      user["verse"] = this.editingitem.verse;
      user["chapter"] = this.editingitem.chapter;
      user["showDate"] = this.editingitem.showDate;
      user["author"] = this.editingitem.author;


      axios({
        url: "/edit/bible/verse",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;

            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/delete/bible/verse",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  }
};
</script>

<style scoped>


.dialog-card {
  font-family: interbold;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.dialog-icon {
  animation: pulse 1s infinite alternate;
}

.dialog-button {
  min-width: 120px;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.7;
  }
  to {
    transform: scale(1.1);
    opacity: 1;
  }
}

.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
  background-color: white;
  table-layout: fixed; /* Ensure fixed table layout for consistent column widths */
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
}

.table th {
  background-color: white;
  font-family: interbold;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.table td {
  font-family: interregular;
  font-size: 15px;
}

.table-row:hover {
  background-color: #eff2f6;
}

/* Ensure the Actions column is styled correctly */
.actions-column {
  text-align: right; /* Align buttons to the right within the cell */
}

/* Flexbox styles to handle alignment of buttons */
.actions-column .v-btn {
  margin-left: 2px; /* Add space between buttons */
}


</style>